













import { Vue, Component } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import { UserNotificationStores } from '@/store/user_notifications';
import UserNotificationTypeForm from '@/components/user_notifications/UserNotificationTypeForm.vue';
import { omit } from 'lodash';

@Component({
  components: {
    Page,
    BackButtonLayout,
    UserNotificationTypeForm,
  },
}) 
export default class EditUserNotificationTypes extends Vue {
  userNotificationStore = UserNotificationStores;

  get userNotificationType() {
    return this.userNotificationStore?.userNotificationType;
  }

  mounted() {
    this.userNotificationStore.getUserNotificationType(Number(this.$route.params.id));
  }

  handleSubmit(payload: any) {
    this.userNotificationStore.updateUserNotificationType({
      id: Number(this.$route.params.id),
      ...(omit(payload, ['type', 'timecreated', 'timemodified']) as any),
    });
  }
}
